import { Dispatch } from 'redux';
import { HttpClient } from '@wix/yoshi-flow-editor';
import { listCategories } from '@wix/ambassador-blog-v3-category/http';
import { Field } from '@wix/ambassador-blog-v3-category/types';
import {
  createAction,
  handleAggregatorResponse,
  isExperimentEnabled,
  normalizeCategory,
} from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '../../../../experiments';
import { Request } from '../../../post-list-widget/types';
import { getQueryLocale } from '../../selectors/locale-selectors';
import { GetState } from '../../types';

export const FETCH_CATEGORIES_SUCCESS = 'categories/FETCH_SUCCESS';

const fetchCategoriesRequest = createAction('categories/FETCH_REQUEST');
export const fetchCategoriesSuccess = createAction(FETCH_CATEGORIES_SUCCESS);
const fetchCategoriesFailure = createAction('categories/FETCH_FAILURE');

const fetchCategoriesAction =
  () =>
  async (
    _dispatch: Dispatch,
    getState: GetState,
    { request, httpClient }: { request: Request; httpClient: HttpClient },
  ) => {
    const state = getState();
    const lang = getQueryLocale(state);
    const langQuery = lang ? `&lang=${lang}` : '';

    const useCategoriesV2Enabled = isExperimentEnabled(
      getState(),
      OOI_EXPERIMENTS.USE_CATEGORIES_V2,
    );

    if (useCategoriesV2Enabled) {
      const response = await httpClient.request(
        listCategories({
          paging: { limit: 100, offset: 0 },
          fieldsToInclude: [Field.TRANSLATIONS, Field.SEO],
          language: lang,
        }),
      );

      return response.data.categories?.map(normalizeCategory);
    }

    return request(
      `/_api/categories?offset=0&size=500&includeTranslations=true&includeSeo=true${langQuery}`,
    );
  };

const createFetchCategories =
  (action: any) =>
  (...args: any) =>
  (dispatch: Dispatch) => {
    dispatch(fetchCategoriesRequest());

    return dispatch(action(...args))
      .then((categories: any) => dispatch(fetchCategoriesSuccess(categories)))
      .catch(() => dispatch(fetchCategoriesFailure()));
  };

const fetchCategories = createFetchCategories(fetchCategoriesAction);

export const handleCategoriesResponse = createFetchCategories(
  handleAggregatorResponse,
);

export default fetchCategories;
